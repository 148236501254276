<template>
    <OAcordion class="accordion row-container" v-if="builder.treeObject.currentNode" :key="builder.treeObject.currentNode">        
        <OAcordionItem :title ="'Props:'+ builder.treeObject.currentNode.item.props.getString()" show>            
            <template v-for="def in builder.treeObject.currentNode.item.props.props"> 
                <PropItem :propItem="def"/>
            </template>
        </OAcordionItem>
          <!--<OAcordionItem :title ="'Columns ('+builder.treeObject.currentNode.item.fields.filter(x=>x.isSelected).length+')'" v-if="builder.treeObject.currentNode && builder.treeObject.currentNode.item.fields.length">
            @onbeforeopen = "builder.treeObject.currentNode.item.addFields"
          -->
        <OAcordionItem :title ="'Columns (' +builder.treeObject.currentNode.item.fields.filter(x=>x.isSelected).length +')'" v-if="builder.treeObject.currentNode.item.fields.length">
            <div class="form-check form-check-inline" v-for="field in builder.treeObject.currentNode.item.fields">
                <input class="form-check-input" type="checkbox" v-model="field.isSelected" :id="'id_column_'+field.name" >
                <label class="form-check-label" :for="'id_column_'+field.name">
                    {{field.name}}
                </label>
            </div>
        </OAcordionItem>
         <OAcordionItem :title ="'Slots ('+ builder.treeObject.currentNode.item.slots.filter(x=>x.selected).length  +')'" v-if="builder.treeObject.currentNode.item.slots.length">
            <div v-for="def in builder.treeObject.currentNode.item.slots">
                 <div class="form-check ">
                    <input class="form-check-input" type="checkbox" v-model="def.selected" :id="'id_slot__'+def.name" >
                    <label class="form-check-label" :for="'id_slot__'+def.name">
                        {{def.name}} - {{def.description}}                        
                    </label>             
                </div>              
            </div>
        </OAcordionItem>
         <OAcordionItem title ="Expose"  v-if="builder.treeObject.currentNode.item.expose.length">
           <div v-for="def in builder.treeObject.currentNode.item.expose">
                <div>{{def.name}}</div>   
            </div>
        </OAcordionItem>
       <!--  <OAcordionItem title ="Events"  v-if="builder.treeObject.currentNode.item.events.length">
           <div v-for="def in builder.treeObject.currentNode.item.events">
                <div>{{def.name}}</div>   
            </div>
        </OAcordionItem>-->
         <OAcordionItem title ="Children" >
            <select class="form-select" placeholder="Add component" @change="$event=>addComponenet($event)">
                <option></option>
                <option :value="comp" v-for="comp in builder.treeObject.currentNode.item.availableChildComponents">
                    {{comp}}
                </option>                   
            </select>
            <div v-for="def in builder.treeObject.currentNode.children">
                <div>{{def.item.caption}} <button class="btn tbn-sm btn-link" @click="builder.removeNode(def)"><i class="bi bi-trash-fill"></i></button></div>   
            </div>
        </OAcordionItem>

        <OAcordionItem :title ="'Examples (' +builder.treeObject.currentNode.item.examples.length+')'"  v-if="builder.treeObject.currentNode.item.examples.length">         
            <Marked class="flex-1" :value="def.exampleMarkdown" style="overflow-y: auto;" v-for="def in builder.treeObject.currentNode.item.examples"></Marked>                     
        </OAcordionItem>         
    </OAcordion>
</template>
<script setup>
import OAcordion from 'o365.vue.components.Acordion.vue';
import OAcordionItem from 'o365.vue.components.AcordionItem.vue';
import Marked from 'o365.vue.components.Marked.vue';
import PropItem from 'o365.vue.components.CodeBuilder.PropItem.vue';
import { watch, computed } from 'vue';

const props = defineProps({
    builder:{
        required:true
    }
});

const addComponenet = (ev)=>{
    if(ev.target.value){
        props.builder.addComponent(ev.target.value);
    }
}

</script>
